import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Typography } from 'antd'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import MainLayout from '@layouts/MainLayout'
import { OutboundLink } from 'gatsby-plugin-gtag'

const shortcodes = {
  // p: Paragraph,
  OutboundLink,
  Link,
  Lead: props => <p className='lead' {...props} />,
  // Paragraph: Typography.Paragraph,
} // Provide common components here

export default function EssayTemplate({ data: { mdx } }) {
  return (
    <MainLayout>
      <div className='blog-post'>
        <Typography.Title className='text-primary-color font-headers font-bold m-0'>
          {mdx.frontmatter.title}
        </Typography.Title>
        <div className='mb-4 text-lg text-gray-500'>
          {mdx.frontmatter.subtitle}
        </div>
        <div className='px-2 py-1 mb-4 bg-gray-200 border-l-8 border-red-400'>
          <div>
            <b>{mdx.fields.readingTime.text}</b>. This was written on{' '}
            <b>
              {mdx.frontmatter.date}, {mdx.frontmatter.time}
            </b>
            . {mdx.frontmatter.flavor}
          </div>
        </div>
        <hr className='my-4' />
        {/* prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto */}
        <div className='prose sm:prose lg:prose-lg xl:prose-2xl mx-auto max-w-none'>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
        <hr className='my-4' />
      </div>
    </MainLayout>
  )
}

EssayTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        flavor
        time
        title
        subtitle
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
